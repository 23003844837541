<template>
  <div>
    <b-container class="p-5">
      <h3>查找职员</h3>
      <b-form-group
          label="Search"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="w-50 mb-3"
      >
        <b-input-group>
          <b-form-input
              id="filter-input"
              v-model="input"
              type="search"
              placeholder="Type to Search"
          ></b-form-input>

          <b-input-group-append>
            <b-button @click="searchStaffs">查询</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <div>

        <b-table :items="staffs" :fields="fields" striped responsive="sm">
          <template #cell(actions)="row">
            <b-button size="sm" @click="row.toggleDetails" class="mx-2">
              {{ row.detailsShowing ? '关闭' : '打开' }}详情
            </b-button>
            <b-button size="sm" @click="goModifyAuthority(row.item.id)" class="mx-2">
              修改权限
            </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <b-row class="mb-2">
                <b-col sm="3" class="text-sm-right"><b>姓名:</b></b-col>
                <b-col>{{ row.item.name }}</b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col sm="3" class="text-sm-right"><b>工号:</b></b-col>
                <b-col>{{ row.item.id }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col sm="3" class="text-sm-right"><b>组织编号:</b></b-col>
                <b-col>{{ row.item.organizationId }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col sm="3" class="text-sm-right"><b>有效日期:</b></b-col>
                <b-col>{{ row.item.validity }}</b-col>
              </b-row>
            </b-card>
          </template>
          <!-- Info modal -->

        </b-table>
      </div>
    </b-container>
    <b-modal id="modal-1" title="修改信息">
    </b-modal>
  </div>
</template>

<script>
import {showStaffsByLikeName} from "@/api";
import ModifyAuthority from "@/components/Search/components/ModifyAuthority";

export default {
  name: "SearchStaff",
  data() {
    return {
      input: '',
      currentPage: 1,
      pageSize: 50,
      staffs: [],
      fields: [{key: 'id', label: '工号'}, {key: 'name', label: '姓名'}, 'actions'],
    }
  },
  mounted() {
    this.searchStaffs()
  },
  methods: {
    searchStaffs() {
      showStaffsByLikeName(this.input, this.currentPage, this.pageSize).then(res => {
        if (res.data.status) {
          this.staffs = res.data.data.content
        }
      })
    },
    goModifyAuthority(id) {
      this.$router.push({name: 'ModifyStaffAuthority', params: {staffId: id}})
    }
  }
}
</script>

<style scoped>

</style>
