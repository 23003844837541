<template>
  <div>
    <b-container class="p-5">
      <b-breadcrumb :items="items"></b-breadcrumb>
      <b-row>
        <b-col :md="6">
          <!--          //已经授予的权限-->
          <b-card class="shadow m-3 p-3">
            <h5>已经授予的权限</h5>
            <b-table striped hover :fields="ownedAuthorityFields" :items="ownedAuthority">
              <template #cell(action)="row">
                <b-button variant="outline-danger" size="sm" @click="revokeAuthority(row.item.id)" class="mr-2">
                  <b-icon icon="x"></b-icon>
                </b-button>
              </template>
            </b-table>
          </b-card>
          <!--          //授予权限 -->
          <b-card class="shadow m-3 p-3">
            <h5>授予权限</h5>
            <b-form>
              <small>根据Id查找</small>
              <b-row class="px-3">
                <b-form-input class="w-75" v-model="text" placeholder="输入权限ID"></b-form-input>
                <b-button class="w-25" @click="addToSelected">添加至列表</b-button>
              </b-row>

              <el-select class="mt-3 w-100" multiple v-model="selected" placeholder="请选择">
                <el-option
                    v-for="item in authority"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
              <div style="text-align: center">
                <b-button class="w-50 mx-auto mt-3" @click="addAuthorities">授予权限</b-button>
              </div>
            </b-form>
          </b-card>
        </b-col>
        <b-col :md="6">
          <!--          //角色管理-->
          <b-card class="shadow m-3 p-3">
            <h5>角色管理</h5>
            <el-select class="mt-3 w-100" v-model="roleSelected" placeholder="请选择">
              <el-option
                  v-for="item in roles"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
            <div style="text-align: center">
              <b-button class="w-50 mx-auto mt-3" @click="grantRole">授予角色</b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  grantAuthority, grantRole,
  revokeAuthority,
  showAuthoritiesGrantedByMeByGranteeId,
  showMyAuthorities,
  showRolesOwnedByMe
} from "@/api";

export default {
  name: "ModifyAuthority",
  data() {
    return {
      tags: [
        {name: '标签一'},
        {name: '标签二'},
        {name: '标签三'},
        {name: '标签四'},
        {name: '标签五'}
      ],
      staffId: 0,
      ownedAuthority: [],
      text: '',
      roles: [],
      roleSelected: '',
      selected: [],
      ownedAuthorityFields: [{key: 'id', label: '权限号'}, {key: 'name', label: '权限名'}, {
        key: 'ownerId',
        label: '归属者'
      }, {key: 'action', label: '操作'}],
      authority: [],
      items: [
        {
          text: '查找员工',
          href: '#'
        },
        {
          text: '修改权限',
          active: true
        }
      ]
    }
  },
  mounted() {
    this.staffId = this.$route.params.staffId
    this.getMyAuthority()
    this.getOwnedAuthority()
    this.showMyRoles()
  },
  methods: {
    addToSelected() {
      let flag = true
      this.authority.forEach(a => {
        if (a.id === Number(this.text)) {
          this.selected.push(Number(this.text))
          flag = false
        }
      })
      if (flag) {
        this.$message.error('没有这样的权限!')
      }
    },
    getOwnedAuthority() {
      showAuthoritiesGrantedByMeByGranteeId(this.staffId, 1, 20).then(res => {
        if (res.data.status) {
          this.ownedAuthority = res.data.data.content
        } else {
          this.$message.error('获取员工已有权限失败')
        }
      })
    },
    getMyAuthority() {
      showMyAuthorities().then(res => {
        if (res.data.status) {
          this.authority = res.data.data
        }
      })
    },
    addAuthorities() {
      if (this.selected !== null) {
        let flag = true
        let errorId = []
        this.selected.forEach(s => {
          grantAuthority(s, this.staffId).then(res => {
            if (res.data.status === false) {
              this.flag = false
              errorId.push({id: s.id, label: s.name})
            }
          })
        })
        if (flag) {
          this.$message.success('权限添加成功!')
          this.getOwnedAuthority()
        } else {
          let errorInfo = ''
          errorId.forEach(e => {
            errorInfo = errorInfo + e.id + ':' + e.label
          })
          this.$message.error(errorInfo + '权限添加失败')
        }
      }
    },
    revokeAuthority(id) {
      revokeAuthority(id, this.staffId).then(res => {
        if (res.data.status) {
          this.$message.success(res.data.message)
          this.getOwnedAuthority()
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    showMyRoles() {
      showRolesOwnedByMe().then(res => {
        if (res.data.status) {
          this.roles = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    grantRole() {
      grantRole(this.roleSelected, this.staffId).then(res => {
        if (res.data.status) {
          this.$message.success(res.data.message)
        } else {
          this.$message.error(res.data.message)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
